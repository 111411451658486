import React, { useEffect, useState, useRef, useCallback } from "react"
import { makeStyles } from "@material-ui/styles"
import { CircularProgress } from "@material-ui/core"
import { SaveAlt, Facebook, Share } from "@material-ui/icons"
import fileSaver from "file-saver"
import theme from "@src/theme"
import { StaticImage } from "gatsby-plugin-image"

const useStyles = makeStyles({
  wrapperRecord: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    background: "black",
    overflow: "auto",
    textAlign: "center",
    padding: "20px 40px",
  },
  downloadSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },
  mediaSection: {
    maxWidth: "402px",
    width: "100%",
    border: "10px solid white",
    marginBottom: "20px",
    marginTop: "20px",
    "@media(max-height: 800px)": {
      maxWidth: "302px",
    },
  },
  mediaResponsive: {
    position: "relative",
    height: 0,
    paddingBottom: "133.33333%",
    width: "100%",
  },
  media: {
    display: "inline-block",
    objectFit: "contain",
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
    transition: "opacity .6s",
  },
  download: {
    position: "relative",
    marginLeft: "10px",
    marginRight: "10px",
    display: "inline-block",
    height: "50px",
    textTransform: "uppercase",
    padding: "10px 35px 12px 60px",
    background: "white",
    color: "black",
    textDecoration: "none",
    borderRadius: "20px",
    fontSize: "20px",
    fontFamily: "Acumin, sans-serif",
    border: "0",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 30px 12px 30px",
      height: "60px",
    },
  },
  icon: {
    fontSize: "26px",
    position: "absolute",
    top: "12px",
    left: "26px",
    [theme.breakpoints.down("sm")]: {
      left: "12px",
      top: "11px",
      fontSize: "36px",
    },
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate3d(-50%, -50%, 0)",
  },
  logo: {
    display: "block",
    height: "100%",
  },
  downloadText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
})

export default props => {
  const { media } = props
  const classes = useStyles()
  const [isReady, setIsReady] = useState(false)
  const mediaRef = useRef()
  const [isShare, setIsShare] = useState(false)

  useEffect(() => {
    setIsShare(!!navigator.share)
  }, [setIsShare])

  useEffect(() => {
    if (media && mediaRef.current) {
      let play = false
      let timeup = false

      const mediaReady = () => setIsReady(true)
      const playingVideo = () => {
        play = true
      }
      const timeupdate = () => {
        if (play && !timeup) {
          mediaReady()
          timeup = true
        }
      }

      if (media.video) {
        mediaRef.current.addEventListener("playing", playingVideo, true)
        mediaRef.current.addEventListener("timeupdate", timeupdate, true)
        mediaRef.current.addEventListener("canplaythrough", mediaReady)
        mediaRef.current.addEventListener("canplay", mediaReady)
        mediaRef.current.load()
      } else {
        mediaRef.current.addEventListener("load", mediaReady)
        mediaRef.current.addEventListener("loadeddata", mediaReady)
      }

      return () => {
        if (media.video) {
          mediaRef.current.removeEventListener("playing", playingVideo, true)
          mediaRef.current.removeEventListener("timeupdate", timeupdate, true)
        } else {
          mediaRef.current.removeEventListener("load", mediaReady)
          mediaRef.current.removeEventListener("loadeddata", mediaReady)
          mediaRef.current.removeEventListener("canplaythrough", mediaReady)
        }
      }
    }
  }, [media])

  const save = useCallback(() => {
    if (media && media.name) {
      fetch(media.video || media.image)
        .then(res => res.blob())
        .then(blob => {
          fileSaver.saveAs(blob, media.name)
        })
    }
  }, [media])

  const share = useCallback(() => {
    if (media && media.url) {
      navigator.share({
        title: "Borne photo Candela",
        text: "Partager vos meilleurs moments grâce à la Borne photo Candela",
        url: media.url,
      })
    }
  }, [media])

  return (
    media && (
      <div className={classes.wrapperRecord}>
        <div>
          <StaticImage
              src="../assets/images/logo.png"
              alt="Candela logo"
              placeholder="blurred"
              layout="fixed"
              width={120}
              height={120}
          />
        </div>
        <div className={classes.mediaSection}>
          <div className={classes.mediaResponsive}>
            <div
              style={{ opacity: isReady ? 0 : 1, color: "white" }}
              className={classes.loading}
            >
              <CircularProgress color={"inherit"} />
            </div>
            {media.video ? (
              <video
                ref={mediaRef}
                style={{ opacity: isReady ? 1 : 0 }}
                className={classes.media}
                src={media.video}
                playsInline="playsInline"
                autoPlay="autoPlay"
                muted="muted"
                loop="loop"
              />
            ) : (
              <img
                ref={mediaRef}
                style={{ opacity: isReady ? 1 : 0 }}
                className={classes.media}
                src={media.image}
              />
            )}
          </div>
        </div>
        <div className={classes.downloadSection}>
          {isShare && (
            <button className={classes.download} onClick={share}>
              <Share className={classes.icon} />{" "}
              <span className={classes.downloadText}>Partager</span>
            </button>
          )}
          <a
            className={classes.download}
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              media.url
            )}&t=${encodeURIComponent("Borne photo Candela")}`}
          >
            <Facebook className={classes.icon} />{" "}
            <span className={classes.downloadText}>Facebook</span>
          </a>
          <button className={classes.download} onClick={save}>
            <SaveAlt className={classes.icon} />{" "}
            <span className={classes.downloadText}>Telecharger</span>
          </button>
        </div>
      </div>
    )
  )
}
