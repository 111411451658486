import React from "react"
import { ThemeProvider, makeStyles } from "@material-ui/styles"
import theme from "@src/theme"
import "@src/assets/fonts/styles.css"

const useStyles = makeStyles({
  "@global": {
    "*": {
      boxSizing: "border-box",
      margin: 0,
      padding: 0,
    },
    html: {
      height: "100%",
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
    },
    body: {
      background: "black",
      height: "100%",
      fontFamily: "Acumin, sans-serif",
    },
  },
})

export default props => {
  const { children } = props

  useStyles()

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
