import React from "react"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  "@global": {
    html: {
      height: "100%",
    },
    body: {
      height: "100%",
      backgroundColor: "black",
    },
  },
  wrapper: {
    maxWidth: "500px",
    margin: "0 auto",
    paddingTop: "200px",
  },
  text: {
    color: "white",
    fontFamily: "sans-serif",
    marginBottom: "30px",
  },
  link: {
    color: "white",
  },
})

export default ({ error }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <p className={classes.text}>{error}</p>
      <p className={classes.text}>
        <a className={classes.link} href="mailto:support@borne-candela.fr">
          Contact
        </a>
      </p>
    </div>
  )
}
