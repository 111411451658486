import React, { useEffect, useState } from "react"
import RecordDetail from "@src/components/RecordDetail"
import RecordError from "@src/components/RecordError"
import Layout from "@src/layout"
import app from 'gatsby-plugin-firebase-v9.0'
import {getFirestore, collection, query, where, limit, getDocs } from "@firebase/firestore"
import {getFunctions, httpsCallable} from "@firebase/functions"

const Record = (props) => {
  const [media] = useState(typeof window !== 'undefined' ? window.dataMedia : undefined)
  const [error, setError] = useState(false)

  useEffect(() => {
    const recordId = props.location.pathname.split("/").slice(-1)[0]

    if (recordId) {
      const db = getFirestore(app);
      const fc = getFunctions(app);
      const q = query(collection(db, "links"), where("shortId", "==", recordId), limit(1))

      getDocs(q).then((querySnapshot) => {
          const dbLinksOpen = httpsCallable(fc,"dbLinksIsOpen")

          querySnapshot.forEach((doc) => {
            return dbLinksOpen({ linkId: doc.id })
          })

          if (querySnapshot.empty) {
            setError(
              `Une erreur est survenue, impossible de charger votre média. Contactez notre service avec le lien ci-dessous (Code: ${recordId})`
            )
          }
        })
        .catch((e) => {
          console.log(e, recordId)
        })
    } else {
      window.location.replace("https://borne-candela.fr")
    }
  }, [])

  return (
    <Layout>
      {error && <RecordError error={error} />}
      {media && <RecordDetail media={media} />}
    </Layout>
  )
}

export default Record
